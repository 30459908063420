import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/1557cf09/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Post Winterkongress 2020 stats`}</h1>
    <h2>{`Infrastructure`}</h2>
    <ul>
      <li parentName="ul">{`6 POE Switches`}</li>
      <li parentName="ul">{`12 Access Points`}</li>
      <li parentName="ul">{`24 Port Core Switch POE`}</li>
      <li parentName="ul">{`1 PFSense Firewall`}</li>
      <li parentName="ul">{`3 Media Converters and various optics (for connecting the two buildings via yolo-link)`}</li>
      <li parentName="ul">{`Raspberry PI & POE-Hat (for DNS Server)`}</li>
      <li parentName="ul">{`Server homed at CommunityRack.org for Livestream`}</li>
      <li parentName="ul">{`2 networks one public and one for device management`}</li>
    </ul>
    <h2>{`Stats`}</h2>
    <ul>
      <li parentName="ul">{`Uplink: 1Gbps`}</li>
      <li parentName="ul">{`Peak Inbound 692 Mpbs (somehow traffic has been spikey ;) )`}</li>
      <li parentName="ul">{`Peak Outbound 236 Mbps`}</li>
      <li parentName="ul">{`Average Inbound 10.38 Mbps`}</li>
      <li parentName="ul">{`Average Outbound 14.59 Mbps`}</li>
      <li parentName="ul">{`Stream Server Total Traffic : 130GB`}</li>
      <li parentName="ul">{`Max Concurrent WiFi Devices: 130`}</li>
    </ul>
    <h1>{`Hi humans at Winterkongress 2020`}</h1>
    <p>{`Here you find Information around the Network Infrastructure at `}<a parentName="p" {...{
        "href": "https://www.digitale-gesellschaft.ch/kongress/2020/"
      }}>{`Winterkongress`}</a></p>
    <h2>{`Wireless 📶`}</h2>
    <p><strong parentName="p">{`SSID:`}</strong>{` Winterkongress
The network is open to everyone - please encrypt your own traffic
You'll receive a public IPv4 and IPv6 IP address - It's 2020 - We say no to NATed networks`}</p>
    <p>{`There is an `}<del parentName="p">{`very limited`}</del>{` amount of wired network ports available - Get in touch with NOC (Network Operations Center) if you need wired internet access. And bring your own cable :-)`}</p>
    <h2>{`DNS Servers 💻`}</h2>
    <p>{`We operate a `}<a parentName="p" {...{
        "href": "https://pi-hole.net/"
      }}>{`Pi-Hole`}</a>{` on IP `}<inlineCode parentName="p">{`45.14.60.2`}</inlineCode>{` and `}<inlineCode parentName="p">{`2a0e:b581::2`}</inlineCode>{` - If you want to enjoy Ad and Tracking free Internet you can use this as your DNS Server
As good use of resources is important to us we run this on a Raspberry Pi 4. The Pi-Hole uses `}<a parentName="p" {...{
        "href": "https://dns.watch/"
      }}>{`DNS.watch`}</a>{` as upstream DNS.`}</p>
    <p><em parentName="p">{`Don't know how to change DNS servers?`}</em>{` Check the guides here: `}<a parentName="p" {...{
        "href": "https://dns.watch/how-to"
      }}>{`https://dns.watch/how-to`}</a></p>
    <h3>{`DNS over TLS / DNS over HTTPS`}</h3>
    <p>{`Alternatively Digitale Gesellschaft provides a `}<a parentName="p" {...{
        "href": "https://www.digitale-gesellschaft.ch/dns/"
      }}>{`DNS over HTTPS / DNS over TLS resolvers`}</a>{`:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`DNS over TLS resolver:`}</strong>{` `}<inlineCode parentName="li">{`dns.digitale-gesellschaft.ch:853`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`DNS over HTTPS resolver:`}</strong>{` `}<a parentName="li" {...{
          "href": "https://dns.digitale-gesellschaft.ch/dns-query"
        }}>{`https://dns.digitale-gesellschaft.ch/dns-query`}</a></li>
    </ul>
    <h2>{`Dashboard 📊`}</h2>
    <p>{`For some fancy graphs of the Winterkongress network: We have `}<em parentName="p">{`a`}</em>{` `}<a parentName="p" {...{
        "href": "http://winterkongress1.nrdy.ch:3000/d/s1mSNdzZz/winterkongress-2020?orgId=1&refresh=30s"
      }}>{`Dashboard`}</a>{`.`}</p>
    <p><em parentName="p">{`USE MOAR BANDWITH!!!!`}</em></p>
    <h2>{`Streaming 📹`}</h2>
    <p>{`One way to produce `}<em parentName="p">{`moar traffic`}</em>{`: `}<a parentName="p" {...{
        "href": "https://streaming.digitale-gesellschaft.ch/"
      }}>{`Video streaming`}</a>{`.`}</p>
    <h2>{`Chat 📖`}</h2>
    <p>{`You'll find us on Matrix in `}<a parentName="p" {...{
        "href": "https://matrix.to/#/!OLvdcuOYmzfewpSLkg:matrix.org?via=matrix.org"
      }}>{`#winterkongress:matrix.org`}</a>{`.`}</p>
    <h2>{`Supporters 💚`}</h2>
    <p>{`This would not be possible without hours of planning and a lot of people supporting us.`}</p>
    <ul>
      <li parentName="ul">{`Network Equipment - `}<a parentName="li" {...{
          "href": "https://strict.ch/"
        }}>{`Strict`}</a></li>
      <li parentName="ul">{`Uplink - `}<a parentName="li" {...{
          "href": "https://www.init7.net/"
        }}>{`Init7`}</a></li>
      <li parentName="ul">{`IP Transit - `}<a parentName="li" {...{
          "href": "https://communityrack.org/"
        }}>{`CommunityRack.org`}</a>{` / `}<a parentName="li" {...{
          "href": "https://www.community-ix.ch/"
        }}>{`Community IX`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      